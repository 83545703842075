var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "suspend" }, [
    _c("div", { staticClass: "bg_gray" }),
    _c(
      "div",
      { staticClass: "box" },
      [
        _c("headbox", {
          attrs: {
            title: "浮悬窗显示品种",
            bgColor: "#f5f5f5",
            isbackPath: true,
            path: "suspends"
          }
        }),
        _c(
          "div",
          { staticClass: "card" },
          _vm._l(_vm.suspendCatgory, function(item) {
            return _c(
              "div",
              {
                key: item.id,
                staticClass: "quotedPrice flex_between",
                on: {
                  click: function($event) {
                    return _vm.changeCatgory(item)
                  }
                }
              },
              [
                _c("div", { staticClass: "text" }, [_vm._v(_vm._s(item.name))]),
                item.def
                  ? _c("van-icon", {
                      attrs: { name: "success", color: "#f1907f" }
                    })
                  : _vm._e()
              ],
              1
            )
          }),
          0
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }